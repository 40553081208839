.pdfViewerPage {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 64px 0;

	@media only screen and (max-width: 800px) {
		padding: 30px 0;

		.backArrowWrapper.backArrowWrapper {
			padding-bottom: 30px;
		}
	}

	@media only screen and (max-width: 600px) {
		.backArrowWrapper.backArrowWrapper {
			padding-bottom: 20px;
		}
	}

	.backArrowWrapper {
		display: flex;
		align-items: center;
		padding-bottom: 32px;

		.backButton {
			margin: 2px 0;
			display: flex;
			align-items: center;
			border: 2px solid black;
			border-radius: 5px;
			padding: 1px 6px 1px 1px;
			cursor: pointer;
			transition: 200ms;
			text-decoration: none;
			color: black;

			&:hover {
				margin-top: 0;
				margin-bottom: 4px;
				transition: 200ms;
			}

			.arrowIcon {
				font-size: 20px;
			}

			.backText {
				padding-left: 3px;
				font-weight: 700;
			}
		}
	}

	button {
		width: 40px;
		height: 30px;
		background-color: white;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 2px solid black;
		border-radius: 5px;
		cursor: pointer;
		margin: 0 5px;

		.arrowIcon {
			color: black;
			font-size: 24px;
		}

		&:active .arrowIcon {
			font-size: 22px;
		}

		&:focus {
			outline: none;
		}

		&:disabled {
			border: 2px solid rgba(16, 16, 16, 0.3);

			& .arrowIcon {
				color: rgba(16, 16, 16, 0.3);
			}
		}
	}

	.pageWrapper {
		.pdfWrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: 500;
			border: 3px solid black;
			border-radius: 20px;

			.pdfViewer {
				&.react-pdf__Document {
					.react-pdf__Page__canvas {
						border-radius: 20px;
					}
				}
			}
		}
	}
}
