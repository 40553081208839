.audiobookPlayerPage {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;

	@media only screen and (max-width: 680px) {
		.pageWrapper.pageWrapper {
			width: 100%;
			box-sizing: border-box;
			padding: 30px;

			.bookTitle {
				font-size: 24px;
			}

			.bookSubtitle {
				font-size: 20px;
			}

			.currentChapter {
				padding-top: 32px;
				font-size: 16px;
			}

			.progressWrapper {
				padding-top: 16px;
			}

				.chapterList {
				font-size: 15px;
				padding-top: 0;
				.listTitle {
					height: 45px;
					padding: 0 0 0 14px;
				}

				.listRow {
					height: 45px;
					padding: 20px 14px;
				}

				.rowName {
				}
			}
		}
	}

	@media only screen and (max-width: 462px) {
		.pageWrapper.pageWrapper {
			.bookTitle {
				line-height: 22px;
				padding-bottom: 4px;
			}
		}
	}

	@media only screen and (max-width: 413px) {
		.pageWrapper.pageWrapper {
			& .rhap_container {
				.rhap_controls-section {
					.rhap_additional-controls {
						.rhap_repeat-button {
							visibility: hidden;
							width: 5px;
						}
					}
					.rhap_main-controls-button {
					}

					.rhap_volume-controls {
						visibility: hidden;
						width: 5px;
					}
				}
				.rhap_progress-indicator {
					width: 12px;
					height: 12px;
					top: -4px;
					margin-left: -5px;
				}

				.rhap_time {
					font-size: 14px;
				}
			}
		}
	}

	.pageWrapper {
		padding: 64px 0;
		width: 600px;

		.backArrowWrapper {
			display: flex;
			align-items: center;
			padding-bottom: 32px;

			.backButton {
				margin:2px 0;
				display: flex;
				align-items: center;
				border: 2px solid black;
				border-radius: 5px;
				padding: 1px 6px 1px 1px;
				cursor: pointer;
				transition: 200ms;
				text-decoration: none;
				color: black;

				&:hover {
					margin-top: 0;
					margin-bottom: 4px;
					transition: 200ms;
				}

				.arrowIcon {
					font-size: 20px;
				}

				.backText {
					padding-left: 3px;
					font-weight: 700;
				}
			}
		}

		.progressWrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			padding-top: 32px;
		}

		& .rhap_container {
			border: 2px solid black;
			border-radius: 10px;
			box-shadow: none;
			font-weight: 700;

			.rhap_progress-indicator {
				background: black;
			}

			.rhap_download-progress {
				background-color: #bfbfbf;
			}

			.rhap_repeat-button {
				color: black;
			}

			.rhap_main-controls-button {
				color: black;
			}

			.rhap_volume-button {
				color: black;
			}

			.rhap_volume-indicator {
				background: black;
			}
		}

		.bookTitle {
			font-size: 32px;
			font-weight: 900;
		}

		.bookSubtitle {
			font-size: 26px;
			font-weight: 700;
			font-style: italic;
		}

		.currentChapter {
			padding-top: 64px;
			padding-bottom: 10px;
			font-size: 20px;
			font-weight: 700;
		}

		.chapterList {
			width: 100%;
			display: flex;
			flex-direction: column;
			padding-top: 10px;
			font-size: 20px;

			.listTitle {
				background-color: black;
				color: white;
				display: flex;
				align-items: center;
				width: 100%;
				font-weight: 700;
				height: 60px;
				box-sizing: border-box;
				padding: 20px;
				border-radius: 16px 16px 0 0;
				margin-top: 32px;
			}

			.listRow {
				color: black;
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				font-weight: 900;
				height: 60px;
				box-sizing: border-box;
				padding: 20px;
				border-color: #484848;
				border-style: solid;
				border-width: 0 2px 2px 2px;
				cursor: pointer;

				.icon {
					color: #0034a9;
				}

				&:hover .rowName {
					text-decoration-color: black;
					transition: 200ms;
				}

				.rowName {
					font-weight: 700;
					padding-left: 20px;
					padding-top: 1px;
					text-decoration: underline;
					text-decoration-color: transparent;
					transition: 200ms;
				}
			}
		}
	}
}
