.homePage {
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	overflow-x: hidden;
	min-height: 100%;
	padding: 20px 0;

	@media only screen and (max-width: 890px) {
		height: fit-content;
		.pageWrapper.pageWrapper {
			width: 604px;
			margin: 64px;
			.listsWrapper {
				flex-direction: column;
				justify-content: center;
				align-items: center;
				width: 100%;
				.list {
					width: 100%;
				}
			}
		}
	}

	@media only screen and (max-width: 620px) {
		.pageWrapper.pageWrapper {
			width: 100%;
			box-sizing: border-box;
			margin: 30px;
		}
	}

	@media only screen and (max-width: 552px) {
		.pageWrapper.pageWrapper {
			width: 100%;
			box-sizing: border-box;
			margin: 40px 20px;

			.titleText {
				font-size: 32px;
				margin-bottom: 24px;
			}

			.listsWrapper {
				.list {
					.listTitle {
						font-size: 16px;
						height: 45px;
						margin-top: 16px;
					}

					.listRow {
						font-size: 16px;
						font-weight: 700;
						height: 45px;
					}

					.rowTitle {
					}
				}
			}
		}
	}

	.pageWrapper {
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.titleText {
			font-size: 64px;
			font-weight: 900;
			text-align: center;
			margin-bottom: 32px;
		}

		.listsWrapper {
			width: 840px;
			display: flex;
			justify-content: space-between;

			.list {
				width: 400px;
				display: flex;
				flex-direction: column;
				.listTitle {
					background-color: black;
					color: white;
					display: flex;
					align-items: center;
					width: 100%;
					font-size: 20px;
					font-weight: 700;
					height: 60px;
					box-sizing: border-box;
					padding: 20px;
					border-radius: 16px 16px 0 0;
					margin-top: 32px;
				}

				.listRow {
					color: black;
					display: flex;
					align-items: center;
					width: 100%;
					font-size: 20px;
					font-weight: 900;
					height: 60px;
					box-sizing: border-box;
					padding: 20px;
					border-color: #484848;
					border-style: solid;
					border-width: 0 2px 2px 2px;
					text-decoration: none;

					.icon {
						color: #0034a9;
					}

					&:hover .rowTitle {
						text-decoration-color: black;
						transition: 200ms;
					}

					.rowTitle {
						padding-left: 20px;
						padding-top: 1px;
						text-decoration: underline;
						text-decoration-color: transparent;
						transition: 200ms;
					}
				}
			}
		}
	}
}
