.header {
	color: black;
	height: 64px;
	display: flex;
	align-items: center;
    font-size: 64px;
	padding-left: 20px;
	border-bottom: 1px solid #cecece;
	box-sizing: border-box;
	text-decoration: none;

	.libraryIcon {
		color: #0034a9;
		font-size: 40px;
	}

	.title {
		font-size: 24px;
		font-weight: 900;
		padding-left: 14px;
	}
}
